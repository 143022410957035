import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { FC, useMemo } from "react";
import { ISectionTitle, ReviewReadableTO } from "../../models/common/common";
import styles from "../../styles/home/Home.TourReview.module.scss";
import { tourReviewTitle } from "../../utils/mockData/homeMocks";
import { randomUid } from "../../utils/randomIds";
import SectionTitle from "../common/SectionTitle";
import CardReview from "./cards/CardReview";

const TourReview: FC<{ reviewContents: ReviewReadableTO[] }> = ({
    reviewContents,
}) => {
    const itemList = useMemo(
        () =>
            reviewContents.map((item: ReviewReadableTO) => ({
                value: item,
                id: randomUid(),
            })),
        [reviewContents]
    );

    const title: ISectionTitle = {
        title: tourReviewTitle,
        titleColumnSize: 10,
        alignment: "justify-content-md-center text-md-left",
        mobileTitleColumnSize: 10,
        classNameContainer: "py-3",
    };

    return (
        <MDBContainer
            fluid
            className={` ${styles.container} px-3 py-0 py-md-5 `}
        >
            <MDBRow
                className="pb-3 pb-md-5 px-0"
                style={{ textAlign: "start" }}
            >
                <SectionTitle sectionTitle={title} />
            </MDBRow>
            <MDBRow
                className="d-flex justify-content-md-center text-md-center pb-5"
                id="tour-reviews"
            >
                <div className="d-flex">
                    <div
                        className={`d-flex justify-content-lg-center justify-content-md-start flex-lg-grow-1 w-100 ${styles.carousel}`}
                    >
                        {itemList.slice(0, 4).map(el => (
                            <React.Fragment key={el.id}>
                                <CardReview content={el.value} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </MDBRow>
        </MDBContainer>
    );
};

export default TourReview;
