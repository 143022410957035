import { MDBContainer } from "mdb-react-ui-kit";
import { FC } from "react";
import styles from "../../styles/home/Home.TikTok.module.scss";
import { tikTokSubTitle, tikTokTitle } from "../../utils/mockData/homeMocks";
import SectionCarouselLayout from "../common/SectionCarouselLayout";
import CardTikTok from "./cards/CardTikTok";
import { Section, VideoreviewsEntity } from "../../models/home";
import { TBreackpointsCarousel } from "../../models/common/common";

const TikTokSection: FC<{
    videoReviews: VideoreviewsEntity[];
    wordingsDelta: Section | undefined;
}> = ({ videoReviews, wordingsDelta }) => {
    const breackPointCarousel: TBreackpointsCarousel = {
        0: {
            slidesPerView: 1.1,
            height: 533,
            spaceBetween: 10,
        },
        576: {
            slidesPerView: 2,
            height: 533,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 3,
            height: 533,
            spaceBetween: 12,
        },
        992: {
            slidesPerView: 3,
            height: 533,
            spaceBetween: 12,
        },
        1200: {
            slidesPerView: 3,
            height: 533,
            spaceBetween: 32,
        },
        1400: {
            slidesPerView: 4,
            spaceBetween: 32,
            height: 400,
        },
        1600: {
            slidesPerView: 4,
            spaceBetween: 32,
            height: 400,
        },
        1920: {
            slidesPerView: 5,
            spaceBetween: 32,
            height: 568,
        },
    };

    return (
        <MDBContainer fluid className={`${styles.container} py-5 px-0`}>
            <SectionCarouselLayout
                visibleSlides={4}
                sectionTitle={tikTokTitle}
                sectionSubTitle={tikTokSubTitle}
                wordingsDelta={wordingsDelta}
                breakpointsCarousel={breackPointCarousel}
                isCarouselContained
                tiktok
            >
                {videoReviews?.map(el => (
                    // eslint-disable-next-line no-underscore-dangle
                    <CardTikTok key={el._id} content={el} />
                ))}
            </SectionCarouselLayout>
        </MDBContainer>
    );
};

export default TikTokSection;
