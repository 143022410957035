export const BREACKPOINT_TABLET = 992;
export const BREACKPOINT_MOBILE = 768;
export const BREACKPOINT_MOBILE_SMALL = 576;
export const BREACKPOINT_DESKTOP = 1200;
export const BREACKPOINT_DESKTOP_LARGE = 1400;
export const BREACKPOINT_DESKTOP_XLARGE = 1600;
export const BREACKPOINT_DESKTOP_XXLARGE = 1800;
export const AWS_REGION = process.env.NEXT_PUBLIC_AWS_REGION || "eu-west-1";
export const AWS_BUCKET_NAME =
    process.env.NEXT_PUBLIC_BUCKET_NAME || "play-italy-dev";
export const REMEMBER_ME_COOKIE_KEY =
    process.env.NEXT_PUBLIC_REMEMBER_ME_COOKIE_KEY ||
    "1c6cecdc-f323-4f1c-b300-6177501f2b8d";
export const REMEMBER_ME_TRUE_DAYS = +(
    process.env.NEXT_PUBLIC_REMEMBER_ME_TRUE_DAYS || 365
);
export const REMEMBER_ME_FALSE_DAYS = +(
    process.env.NEXT_PUBLIC_REMEMBER_ME_FALSE_DAYS || 0.02
);

export const FETCH_TIMEOUT = +(process.env.NEXT_PUBLIC_FETCH_TIMEOUT || 10000);
export const FETCH_TIMEOUT_SERVER = +(
    process.env.NEXT_PUBLIC_FETCH_TIMEOUT_SERVER || 20000
);

export const URLS = {
    italianStories: "/italian-stories",
    contatcts: "/contacts",
    login: "/login",
    signup: "/sign-up",
    booking: "/booking",
    userProfile: "/user-page",
    privacyPolicies: "/info/privacy",
    terms: "/info/terms",
    allTours: "/tours-destinations",
    playWhitUs: "/play-with-us",
    home: "/",
    faq: "/faq",
    acrossItaly: "/tours-destinations?tourType=italy&destination=",
    intoTheRegion: "/tours-destinations?tourType=region&destination=",
    intoTheCity: "/tours-destinations?tourType=city&destination=",
    rome: "/tours-destinations?tourType=&destination=Rome",
    naples: "/tours-destinations?tourType=&destination=Naples",
    venice: "/tours-destinations?tourType=&destination=Venice",
    florence: "/tours-destinations?tourType=&destination=Florence",
    milan: "/tours-destinations?tourType=&destination=Milan",
};

export const reviewsContainer = {
    id: "tour-reviews",
    hash: "reviews",
};
