import {
    HeadSectionContent,
    SectionsTitleText,
} from "../../models/common/common";
import { UserReadableTO } from "../../models/detail";
import { TAccountModel } from "../../models/userPage";
import colors from "../../styles/colors.module.scss";

const headTitle: SectionsTitleText[] = [
    {
        text: "Your Account",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: true,
    },
];

export const headData = (headImage?: string): HeadSectionContent => {
    console.log("headImage", headImage);
    return {
        backgroundImageUrl: headImage || "",
        title: headTitle,
        subTitle: [],
    } as HeadSectionContent;
};

export const userMocks: UserReadableTO[] = [
    {
        userId: "hibgjwhw",
        nickname: "AndreaVerde99",
        name: "Andrea",
        lastname: "Verde",
        email: "hzdkv@example.com",
        phone: "01234567890",
        bio: "This is my bio",
        dateOfBirth: "2015-04-03",
        gender: "Male",
        cityOfBirth: "Sao Paulo",
        taxIdCode: "123456789",
        address: "Avenida 1",
        languagePreference: "it",
    },
    {
        userId: "2wuiowsqh",
        nickname: "Spyro",
        name: "Alessandro",
        lastname: "Dagostino",
        email: "hzdkv@example.com",
        phone: "01234567890",
        bio: "This is my bio test of Spyro",
        dateOfBirth: "2015-10-12",
        gender: "Male",
        cityOfBirth: "Italy",
        taxIdCode: "123456789",
        address: "Via roma 15",
        languagePreference: "fr",
    },
];

export const AccountData: TAccountModel[] = [
    {
        iconName: "/images/Home/Line.svg",
        title: "Lives in",
        description: "Lorem ipsum dolor",
    },
    {
        iconName: "/images/Line.svg",
        title: "Speaks",
        description: "Lorem ipsum dolor",
    },
];

export const titleDeteleteCard: SectionsTitleText[] = [
    {
        text: "Are you sure?",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const subTitleDeteleteCard: SectionsTitleText[] = [
    {
        text: "Once the card has been deleted, you will need to insert it to have it in future checkouts.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
