import { MDBContainer } from "mdb-react-ui-kit";
import { useRouter } from "next/router";
import { FC, useMemo } from "react";
import { DestinationToExploreEntity, Section } from "../../models/home";
import {
    wantTovisitSubTitle,
    wantTovisitTitle,
} from "../../utils/mockData/homeMocks";
import SectionCarouselLayout from "../common/SectionCarouselLayout";
import CardVisit from "./cards/CardVisit";
import { TBreackpointsCarousel } from "../../models/common/common";

const IwantToVisit: FC<{
    destinations: DestinationToExploreEntity[];
    wordingsDelta: Section | undefined;
}> = ({ destinations, wordingsDelta }) => {
    const router = useRouter();

    const visibleCards = useMemo(() => {
        return destinations.map(destination => {
            return (
                <CardVisit
                    key={destination.aggregationId}
                    content={destination}
                    onClick={() => {
                        router.push({
                            pathname: "/tours-destinations",
                            query: {
                                destination: destination.city,
                                tourType: null,
                            },
                        });
                    }}
                />
            );
        });
    }, [destinations]);

    const breackPointCarousel: TBreackpointsCarousel = {
        0: {
            slidesPerView: 1.3,
            height: 533,
            spaceBetween: 12,
        },
        576: {
            slidesPerView: 2,
            height: 533,
            spaceBetween: 12,
        },
        768: {
            slidesPerView: 2.5,
            height: 533,
            spaceBetween: 12,
        },
        992: {
            slidesPerView: 3,
            height: 533,
            spaceBetween: 12,
        },
        1200: {
            slidesPerView: 3.5,
            height: 533,
            spaceBetween: 32,
        },
        1400: {
            slidesPerView: 3.5,
            spaceBetween: 32,
            height: 568,
        },
        1600: {
            slidesPerView: 4.5,
            spaceBetween: 32,
            height: 568,
        },
        1920: {
            slidesPerView: 5.5,
            spaceBetween: 32,
            height: 568,
        },
    };

    return (
        <MDBContainer fluid className="py-5 px-0">
            <SectionCarouselLayout
                visibleSlides={3.5}
                sectionTitle={wantTovisitTitle}
                sectionSubTitle={wantTovisitSubTitle}
                wordingsDelta={wordingsDelta}
                isCarouselContained={false}
                breakpointsCarousel={breackPointCarousel}
            >
                {visibleCards}
            </SectionCarouselLayout>
        </MDBContainer>
    );
};

export default IwantToVisit;
