import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useRouter } from "next/router";
import { FC, useMemo, useState } from "react";
import { ISectionTitle } from "../../../models/common/common";
import { CouruselStatus } from "../../../models/common/enums";
import styles from "../../../styles/home/Home.ItalianTour.module.scss";
import {
    sectionSubTitle,
    sectionTitle,
} from "../../../utils/mockData/homeMocks";
import { randomUid } from "../../../utils/randomIds";
import SectionTitle from "../../common/SectionTitle";
import ItalianTourCarousel from "./ItalianTourCarousel";
import ItalianTourButtons from "./ItalianTourButtons";
import { Section, ToursByTourTypeEntity } from "../../../models/home";
import CardTour from "../cards/cardTour/CardTour";
import colors from "../../../styles/colors.module.scss";

const couruselButtonTabs = [
    {
        id: randomUid(),
        name: "Down the City",
        status: CouruselStatus.DOWNTHECITY,
    },
    {
        id: randomUid(),
        name: "Into the Region",
        status: CouruselStatus.INTOTHEREGION,
    },
    {
        id: randomUid(),
        name: "Across Italy",
        status: CouruselStatus.ACROSSITALY,
    },
];

const ItalianTour: FC<{
    toursByType: ToursByTourTypeEntity[];
    wordingsDelta: Section | undefined;
}> = ({ toursByType, wordingsDelta }) => {
    const [status, setStatus] = useState<CouruselStatus>(
        CouruselStatus.DOWNTHECITY
    );

    const router = useRouter();
    const viewAllPath = "/tours-destinations";

    const visibleCards = useMemo(() => {
        return (
            toursByType
                .find(
                    element =>
                        element.tourType.toLowerCase() === status.toLowerCase()
                )
                ?.tours?.map(tour => {
                    /* eslint-disable no-underscore-dangle */
                    return (
                        <CardTour
                            className={styles.italianTourCard}
                            key={tour._id}
                            content={tour}
                        />
                    );
                    /* eslint-enable no-underscore-dangle */
                }) ?? []
        );
    }, [status]);

    const [resetIndex, setResetIndex] = useState<boolean>(false);

    const getTourQuery = (carouselStatus: CouruselStatus) => {
        let query = "";
        // eslint-disable-next-line default-case
        switch (carouselStatus) {
            case CouruselStatus.ACROSSITALY:
                query = "italy";
                break;
            case CouruselStatus.INTOTHEREGION:
                query = "region";
                break;
            case CouruselStatus.DOWNTHECITY:
                query = "city";
                break;
        }
        return query;
    };

    const sectionTitleData: ISectionTitle = {
        title: sectionTitle,
        subTitle: sectionSubTitle,
        alignment:
            "justify-content-start text-align-left justify-content-sm-center text-sm-center",
        fluid: true,
        classNameSubTitle: `${styles.italianTourParagraph} ${colors.textGray}`,
        classNameTitle: styles.italianTourTitle,
        classNameTitleContainer: styles.italianTourTitleContainer,
        classNameContainer: "py-3",
    };

    return (
        <MDBContainer fluid className={`${styles.italianTour} p-0`}>
            <MDBRow className="justify-content-center p-md-5">
                <MDBCol
                    lg={11}
                    className={`${styles.italianTourContent} flex-fill pt-5 my-md-5`}
                >
                    <MDBRow className="d-flex justify-content-center px-3 px-md-5">
                        <MDBCol size={12}>
                            <SectionTitle
                                sectionTitle={sectionTitleData}
                                wordingsDelta={wordingsDelta}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="px-4">
                        <ItalianTourButtons
                            setResetIndex={setResetIndex}
                            carouselButtons={couruselButtonTabs}
                            setStatus={setStatus}
                            status={status}
                        />
                    </MDBRow>

                    <MDBRow
                        className={`${styles.mobileHeight} ${styles.desktopHeight} py-2 mx-2`}
                    >
                        <ItalianTourCarousel
                            setResetIndex={setResetIndex}
                            resetIndex={resetIndex}
                            containerClassName="pb-0"
                        >
                            {visibleCards}
                        </ItalianTourCarousel>
                    </MDBRow>

                    <MDBRow className="d-flex justify-content-center px-md-5">
                        <MDBCol
                            size={12}
                            className="mx-5 pb-2 m-5 d-flex justify-content-center"
                        >
                            <MDBBtn
                                className={`${styles.buttonMainAction} mx-2 shadow-0`}
                                color="danger"
                                onClick={() => {
                                    router.push({
                                        pathname: viewAllPath,
                                        query: {
                                            tourType: getTourQuery(status),
                                            destination: null,
                                        },
                                    });
                                }}
                            >
                                View All
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default ItalianTour;
