import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FC, useEffect, useState } from "react";
import { Section } from "@/models/home";
import { convertDeltaToHtmlConverter } from "@/utils/common/delta";
import { ISectionTitle, SectionsTitleText } from "../../models/common/common";
import styles from "../../styles/common/SectionTitle.module.scss";
import { randomUid } from "../../utils/randomIds";

const SectionTitle: FC<{
    sectionTitle: ISectionTitle;
    wordingsDelta?: Section;
}> = ({ sectionTitle, wordingsDelta }) => {
    // Se viene passato il delta prevale sulla sectionTitle
    const [titleItemList, setTitleItemList] = useState<
        { value: SectionsTitleText; id?: string }[]
    >([]);
    const [subtitleItemList, setSubtitleItemList] = useState<
        { value: SectionsTitleText; id?: string }[]
    >([]);

    const {
        classNameContainer,
        alignment,
        mobileTitleColumnSize,
        fluid,
        classNameTitle,
        classNameSubTitle,
        classNameTitleContainer,
        titleColumnSize,
    } = sectionTitle;

    const formatText = (
        sectionText: { value: SectionsTitleText; id?: string }[]
    ) => {
        return (
            <>
                {sectionText.map(el => (
                    <span key={el.id}>
                        <span
                            className={`${el.value.color} ${el.value.fontWeight}`}
                        >
                            {el.value.text}
                        </span>{" "}
                        {el.value.newLine && <br />}
                    </span>
                ))}
            </>
        );
    };

    const sizesTitle = fluid
        ? {}
        : {
              size: mobileTitleColumnSize || 12,
              md: titleColumnSize,
          };

    useEffect(() => {
        setTitleItemList(
            sectionTitle.title.map((item: SectionsTitleText) => ({
                value: item,
                id: randomUid(),
            }))
        );

        if (sectionTitle.subTitle) {
            setSubtitleItemList(
                sectionTitle.subTitle.map((item: SectionsTitleText) => ({
                    value: item,
                    id: randomUid(),
                }))
            );
        }
    }, [sectionTitle]);

    return (
        <MDBRow className={`${classNameContainer} d-flex ${alignment}`}>
            <MDBCol {...sizesTitle} className={classNameTitleContainer}>
                <h1 className={`${classNameTitle} ${styles.textTitle}`}>
                    {wordingsDelta?.title ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: convertDeltaToHtmlConverter(
                                    wordingsDelta?.title
                                ),
                            }}
                        />
                    ) : (
                        formatText(titleItemList)
                    )}
                </h1>
            </MDBCol>
            {wordingsDelta?.subtitle ? (
                <div
                    className={`${styles.textSubTitle} ${classNameSubTitle}`}
                    dangerouslySetInnerHTML={{
                        __html: convertDeltaToHtmlConverter(
                            wordingsDelta?.subtitle
                        ),
                    }}
                />
            ) : (
                sectionTitle.subTitle && (
                    <p
                        className={`${styles.textSubTitle} ${classNameSubTitle}`}
                    >
                        {formatText(subtitleItemList)}
                    </p>
                )
            )}
        </MDBRow>
    );
};

export default SectionTitle;
